<template>
  <div class="videoNav">
    <div class="nav-con">
      <div class="nav-left">
        <div class="title-log">
          <div class="title-img" @click="ongoIndex">
            <div class="bg-img"></div>
          </div>
          <div class="title-white" @click="onGoVideoIndex">视频资源库</div>
        </div>
      </div>
      <div class="nav-right">
        <div class="other">
          <div class="select">
            <el-select v-model="value" filterable placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="search">
            <el-input v-model="search" placeholder="请输入搜索内容" @keyup.enter.native="onSearch"></el-input>
            <div class="icon el-icon-search" @click="onSearch"></div>
          </div>
          <div class="logo" v-if="!$rtm.store.logined">
            <el-input v-model="userName" placeholder="用户名"></el-input>
            <el-input v-model="passWord" placeholder="密码" show-password></el-input>
            <div class="backpass">找回密码>></div>
            <el-input class="yanzheng" v-model="code" placeholder="验证码" @keyup.enter.native="onLogoin"></el-input>
            <img :src="codeImg" alt="" class="yan-img" v-if="!$rtm.store.logined" @click="onLogo" />
            <div class="logon">
              <el-button class="logoin" @click="onLogoin">登录</el-button>
              <el-button class="register" @click="onRegister(2)">注册</el-button>
            </div>
          </div>
          <div class="logoed" v-else>
            <div class="uploadVideo" @click="onUploadVideo" v-if="noUpload">上传视频</div>
            <div class="myVideo" @click="onMyVideo">我的视频</div>
            <div class="logoOut" @click="onLogoOut">退出登录</div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-content">
      <div class="nav-link">
        <div class="nav-list" v-for="(k, nIndex) in navData" :key="nIndex + 'na_'" @click="onLookVideo(k.name, nIndex)">
          <div class="nav-title">{{ k.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from '@/api/RD'
import sha1 from 'js-sha1'

export default {
  data() {
    return {
      options: [
        {
          value: '选项1',
          label: '关键词',
        },
        {
          value: '选项2',
          label: '图片',
        },
      ],
      value: '选项1',
      search: '',
      userName: '', // 用户名
      passWord: '', // 密码
      codeImg: '', // 验证码
      code: '', // 验证码
      noUpload: false, // 是否显示上传视频的按钮
      navData: [
        { name: '热点' },
        { name: '滚动' },
        { name: '推荐' },
        { name: '赛事' },
        { name: '经济' },
        { name: '社会' },
        { name: '民生' },
        { name: '环境' },
        { name: '下载' },
        { name: '上传' },
      ],
    }
  },
  created() {
    var thiz = this
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        thiz.$rtm.store.userInfo = data
        thiz.$rtm.store.logined = true
        if (data.approveStatusName == '未通过') {
          thiz.noUpload = false
        } else if (data.approveStatusName == '已通过' && data.upVideoStores.length != 0) {
          thiz.noUpload = true
        }
        thiz.reload()
      })
      .catch(() => {})
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    //  登录
    onLogoin() {
      var thiz = this
      console.log('code', thiz.code)
      if (isNaN(thiz.code)) {
        RD.pure()
          .user()
          .login(thiz.code, thiz.userName, sha1(thiz.passWord))
          .then((data) => {
            thiz.$rtm.store.userInfo = data
            if (data.approveStatusName == '未通过') {
              thiz.noUpload = false
            } else if (data.approveStatusName == '已通过' && data.upVideoStores.length != 0) {
              thiz.noUpload = true
            }
            thiz.$rtm.store.logined = true
            // thiz.loginFlag = 2;
            thiz.userName = ''
            thiz.passWord = ''
            thiz.code = ''
            thiz.loading = false
            // thiz.load();
            thiz.$router.replace('/video.html').catch(() => {
              thiz.reload()
            })
          })
          .catch((error) => {
            thiz.$message(error.msg)
            thiz.userName = ''
            thiz.passWord = ''
            thiz.code = ''
            thiz.loading = false
            thiz.onLogo()
          })
      } else {
        thiz.$message('请输入验证码再登录')
      }
    },
    // 图形验证码刷新
    onLogo() {
      var thiz = this
      thiz.logoShow = true
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    // 回欢迎页
    ongoIndex() {
      this.$router.push('/index.html')
    },
    // 退出登录
    onLogoOut() {
      var thiz = this
      RD.user()
        .logout()
        .then(() => {
          thiz.$rtm.store.logined = false
          thiz.$router.push('/video.html')
          thiz.onLogo()
        })
    },
    // 注册页面
    onRegister(type) {
      this.$router.push({
        path: '/register.html',
        query: { id: type },
      })
    },
    onUploadVideo() {
      this.$router.push('/uploadVideo.html')
    },
    onMyVideo() {
      this.$router.push('/myVideo.html')
    },
    // 返回视频首页
    onGoVideoIndex() {
      this.$router.push('/video.html')
    },
    // 进入搜索页面
    onSearch() {
      this.$message('页面建设中，等稍等')
    },
    // 视频的导航点击
    onLookVideo(name, index) {
      console.log('name', name)
      console.log('id', index)
      if (name == '热点') {
        this.$router.push({
          path: '/videoFixtopic.html',
          query: { id: 41 },
        })
      } else if (name == '滚动') {
        this.$router.push({
          path: '/videoFixtopic.html',
          query: { id: 42 },
        })
      } else if (name == '推荐') {
        this.$router.push({
          path: '/videoFixtopic.html',
          query: { id: 43 },
        })
      } else if (name == '赛事') {
        this.$router.push({
          path: '/videoFixtopic.html',
          query: { id: 44 },
        })
      } else if (name == '经济') {
        this.$router.push({
          path: '/videoFixtopic.html',
          query: { id: 45 },
        })
      } else if (name == '社会') {
        this.$router.push({
          path: '/videoFixtopic.html',
          query: { id: 56 },
        })
      } else if (name == '民生') {
        this.$router.push({
          path: '/videoFixtopic.html',
          query: { id: 57 },
        })
      } else if (name == '环境') {
        this.$router.push({
          path: '/videoFixtopic.html',
          query: { id: 58 },
        })
      } else if (name == '上传') {
        if (this.$rtm.store.logined) {
          console.log('loged')
          this.$router.push('/uploadVideo.html')
        } else {
          this.$message('请先登录账号')
        }
      } else if (name == '下载') {
        this.$message('页面建设中')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.videoNav {
  height: auto;
  margin-bottom: 20px;
  margin-top: 10px;
  .nav-con {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 100px;
    .nav-left {
      width: 40%;
      height: 100%;
      position: relative;
      .title-log {
        width: 382px;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        .title-img {
          position: absolute;
          left: 0px;
          top: -35px;
          width: 67%;
          height: 130px;
          border-right: 1px solid #ccc;
          cursor: pointer;
          .bg-img {
            position: absolute;
            left: -20px;
            top: 29px;
            width: 260px;
            height: 100px;
            background: no-repeat url(../assets/logo.png);
            background-size: cover;
            transform: scale(1.3);
          }
        }
        .title-white {
          width: 185px;
          height: 51px;
          line-height: 51px;
          position: absolute;
          left: 67%;
          top: 23px;
          background-color: #005573;
          color: #fff;
          font-size: 2rem;
          cursor: pointer;
        }
      }
    }
    .nav-right {
      width: 65%;
      margin-left: 2%;
      .nav-title {
        height: 50%;
        display: flex;
        background-color: #00b2ec;
        .nav-list {
          width: 100px;
          height: 100%;
          line-height: 50px;
          color: #fff;
          font-size: 1.5rem;
        }
      }
      .other {
        display: flex;
        margin-top: 28px;
        position: relative;
        .select {
          width: 90px;
        }
        .search {
          width: 178px;
          display: flex;
          position: relative;
          .el-input {
            width: 170px;
            margin: 0px 5px;
          }
          .icon {
            position: absolute;
            right: 5px;
            top: 0px;
            width: 50px;
            font-size: 2.5rem;
            padding-top: 5px;
            border-radius: 5px;
            height: 30px;
            cursor: pointer;
          }
        }
        .logo {
          display: flex;
          position: absolute;
          right: 0px;
          top: 0px;
          height: 40px;
          .el-input {
            width: 95px;
            padding: 0px;
            margin: 0px 1px;
          }
          .yan {
            width: 50px;
            border: 1px solid #ccc;
            line-height: 45px;
            height: 38px;
            margin-right: 3px;
          }
          .yan-img {
            width: 100px;
            height: 40px;
            background: no-repeat 50% / cover;
          }
          .yanzheng {
            width: 85px;
          }
          .backpass {
            position: absolute;
            right: 250px;
            top: 50px;
            cursor: pointer;
          }
          .el-button {
            width: 60px;
            height: 40px;
            margin: 0px;
            padding: 0px;
          }
          .logon {
            width: 60px;
            margin-left: 5px;
          }
        }
        .logoed {
          display: flex;
          position: absolute;
          top: 0px;
          right: 0px;
          height: 40px;
          line-height: 40px;
          font-size: 1.2rem;
          .uploadVideo,
          .logoOut {
            width: 60px;
            border: 1px solid #ccc;
            cursor: pointer;
          }
          .myVideo {
            width: 60px;
            border: 1px solid #ccc;
            cursor: pointer;
          }
        }
      }
    }
  }
  .nav-content {
    width: 100%;
    height: 30%;
    display: flex;
    margin: 20px 0px;
    background-color: #898989;
    position: relative;
    color: #fff;
    .nav-log {
      width: 30px;
      height: 50px;
      font-size: 50px;
      display: flex;
      justify-content: flex-start;
      div {
        width: 30px;
        height: 50px;
        font-size: 30px;
        line-height: 50px;
      }
    }
    .nav-link {
      width: 1200px;
      height: 50px;
      margin: 0 auto;
      display: flex;
      .nav-list {
        flex: 1;
        cursor: pointer;
        .nav-title {
          font-size: 1.4rem;
          margin-right: 20px;
          height: 50px;
          line-height: 50px;
          width: 100%;
        }
      }
      .nav-list.active {
        border-bottom: 3px solid red;
      }
    }
    .nav-number {
      width: 200px;
      height: 50px;
      line-height: 50px;
      font-size: 1.3rem;
      font-weight: bold;
      display: flex;
      margin-top: 10px;
      .fapian {
        width: 70px;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        color: red;
        padding-right: 5px;
        cursor: pointer;
      }
      .number {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        text-align: left;
      }
    }
  }
}
</style>
