<template>
  <div class="whole">
    <div class="bottom desktop-present">
      <div class="bottom-con">
        <div class="logo">
          <div class="logo-bg">
            <img src="@/assets/bglogo.png" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="text">分会秘书处地址：浙江宁波余姚市南雷南路1号余姚市融媒体中心2410室</div>
          <div class="text">总站编辑部地址：浙江宁波余姚市南雷南路1号县市图片网络中心</div>
          <div class="text">联系电话：0574-58129128 邮政编码：315400</div>
          <div class="text">余姚市融媒体中心版权所有 未经授权禁止复制或建立镜像  <a style="color: #fff" href="https://beian.miit.gov.cn" target="_blank">浙ICP备11048874号</a></div>
        </div>
      </div>
    </div>
    <div class="bottom mobile-present">
      <div class="bottom-con">
        <div class="logo">
          <div class="logo-bg">
            <img src="@/assets/bglogo.png" alt="" />
          </div>
        </div>
        <div class="content">
          <div class="text">分会秘书处地址：浙江宁波余姚市南雷南路1号余姚市融媒体中心2410室</div>
          <div class="text">总站编辑部地址：浙江宁波余姚市南雷南路1号县市图片网络中心</div>
          <div class="text">联系电话：0574-58129128 邮政编码：315400</div>
          <div class="text">余姚市融媒体中心版权所有 未经授权禁止复制或建立镜像  <a style="color: #fff" href="https://beian.miit.gov.cn" target="_blank">浙ICP备11048874号</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.whole {
  position: relative;
  height: 28rem;
  max-height: 280px;
  width: 100%;
  background: no-repeat url(../assets/bottom.jpg);
  background-size: cover;
  background-position: 50% 100%;
}
.bottom.desktop-present {
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
  box-sizing: border-box;
  overflow: hidden;
  .bottom-con {
    height: 100%;
    position: relative;
    display: flex;
    width: 120rem;
    margin: 0 auto;
    .logo {
      width: 50rem;
      height: 55%;
      display: flex;
      margin-top: 3rem;
      .logo-bg {
        width: 100%;
        height: 74%;
        img {
          width: 78%;
          height: 86%;
          margin-top: 1rem;
          margin-left: 9rem;
          background-size: cover;
        }
      }
    }
    .content {
      width: 65rem;
      margin: 0 auto;
      height: 55%;
      font-size: 12px;
      text-align: right;
      margin-top: 5rem;
      margin-left: 0px;
      .text {
        width: 100%;
        line-height: 2.3rem;
        color: #fff;
        span {
          margin-right: 2.5rem;
        }
      }
      .two {
        color: #fff;
        width: 100rem;
        margin: 0 auto;
        height: 3rem;
        display: flex;
        .two-con {
          width: 100%;
          span {
            display: inline-block;
            width: 6.9rem;
            border-right: 1px solid #ccc;
          }
        }
      }
    }
  }
}
.bottom.mobile-present {
  position: relative;
  height: 420px;
  width: 750px;
  background: no-repeat url(../assets/bottom.jpg);
  background-size: cover;
  .bottom-con {
    height: 110px;
    background: rgba(0, 0, 0, 0.7);
    padding-top: 8px;
    position: relative;
    .logo {
      width: 400px;
      height: auto;
      display: flex;
      .logo-bg {
        width: 100%;
        height: 100%;
        margin-top: 2rem;
      }
      .logo-text {
        color: #fff;
        text-align: left;
        font-size: 2rem;
        margin-left: 0.5rem;
        margin-top: 2.8rem;
        .title-large {
          font-size: 2.2rem;
          height: 4rem;
          line-height: 4rem;
          letter-spacing: 10px;
          margin-left: 1px;
        }
        .title-small {
          font-size: 1.2rem;
          transform: scale(1, 1.6);
          -ms-transform: scale(1, 1.6);
          -webkit-transform: scale(1, 1.6);
          -moz-transform: scale(1, 1.6);
          -o-transform: scale(1, 1.6);
        }
      }
    }
    .content {
      width: 600px;
      margin: 12px auto 0px;
      height: 100px;
      font-size: 14px;
      .text {
        height: 30px;
        width: 600px;
        color: #fff;
        ::v-deep a {
          color: #fff;
          background-color: #fff;
        }
      }
    }
  }
}
</style>
