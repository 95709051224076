import { render, staticRenderFns } from "./videoNav.vue?vue&type=template&id=c53c5a4c&scoped=true&"
import script from "./videoNav.vue?vue&type=script&lang=js&"
export * from "./videoNav.vue?vue&type=script&lang=js&"
import style0 from "./videoNav.vue?vue&type=style&index=0&id=c53c5a4c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c53c5a4c",
  null
  
)

export default component.exports