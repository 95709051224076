import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import videoHome from "../views/videoHome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index.html"
  },
  {
    path: "/index.html",
    component: () => import("../views/Index.vue")
  },
  // {
  //   path: "/index.html",
  //   component: () => import("../views/emptyIndex.vue")
  // },
  {
    path: "/memberIndex.html",
    component: () => import("../views/memberIndex.vue")
  },
  {
    path: "/post.html",
    component: () => import("../views/postDetail.vue")
  },
  {
    path: '/vote.html',
    component: () => import('../views/vote.vue')
  },
  {
    path: '/newPosts.html',
    component: () => import('../views/newPosts.vue')
  },
  {
    path: "/catalog.html",
    component: () => import("../views/catalog.vue")
  },
  {
    path: "/videoCatalog.html",
    component: () => import("../views/videoCatalog.vue")
  },
  {
    path: "/articalePaint.html",
    component: () => import("../views/articalePaint.vue")
  },
  {
    path: "/activityPage.html",
    component: () => import("../views/activityPage.vue")
  },
  {
    path: "/upPost.html",
    component: () => import("../views/upPost.vue")
  },
  {
    path: "/myInfo.html",
    component: () => import("../views/myInfo.vue")
  },
  {
    path: "/myPost.html",
    component: () => import("../views/myPost.vue")
  },
  {
    path: "/bindMobile.html",
    component: () => import("../views/bindMobile.vue")
  },
  {
    path: "/userShow.html",
    component: () => import("../views/userShow.vue")
  },
  {
    path: "/postEdit.html",
    component: () => import("../views/postEdit.vue")
  },
  {
    path: "/updataInfo.html",
    component: () => import("../views/updataInfo.vue")
  },
  {
    path: "/resetPassword.html",
    component: () => import("../views/resetPassword.vue")
  },
  {
    path: "/userShowList.html",
    component: () => import("../views/userShowList.vue")
  },
  {
    path: "/userShowDetail.html",
    component: () => import("../views/userShowDetail.vue")
  },
  {
    path: "/gongGao.html",
    component: () => import("../views/gongGao.vue")
  },
  {
    path: "/local.html",
    component: () => import("../views/local.vue")
  },
  {
    path: "/localCity.html",
    component: () => import("../views/localCity.vue")
  },
  {
    path: "/localShow.html",
    component: () => import("../views/localStore.vue")
  },
  {
    path: "/localNodes.html",
    component: () => import("../views/localNodes.vue")
  },
  {
    path: "/xieyi.html",
    component: () => import("../views/xieyi.vue")
  },
  {
    path: "/fixTopic.html",
    component: () => import("../views/fixTopic.vue")
  },
  {
    path: "/buy.html",
    component: () => import("../views/buy.vue")
  },
  {
    path: "/activity.html",
    component: () => import("../views/activity.vue")
  },
  {
    path: "/advertisement.html",
    component: () => import("../views/advertisement.vue")
  },
  {
    path: "/register.html",
    component: () => import("../views/register.vue")
  },
  {
    path: "/search.html",
    component: () => import("../views/search.vue")
  },
  {
    path: "/announceDetail.html",
    component: () => import("../views/announceDetail.vue")
  },
  {
    path: "/forgetPassWord.html",
    component: () => import("../views/forgetPassWord.vue")
  },
  {
    path: "/picSearch.html",
    component: () => import("../views/picSearch.vue")
  },
  {
    path: "/video",
    name: "Video",
    redirect: "/index/video.html",
    component: videoHome,
    children: []
  },
  {
    path: "/uploadVideo.html",
    component: () => import("../views/uploadvideo.vue")
  },
  {
    path: "/video.html",
    component: () => import("../views/video/index.vue")
  },
  {
    path: "/videoFixtopic.html",
    component: () => import("../views/video/videoFixtopic.vue")
  },
  {
    path: "/videoDetail.html",
    component: () => import("../views/video/videoDetail.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
