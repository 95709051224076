<template>
  <div class="root">
    <videoNav></videoNav>
    <router-view v-if="videoAlive" />
    <videoBottom></videoBottom>
  </div>
</template>

<script>
// @ is an alias to /src
import videoNav from "@/components/videoNav.vue";
import videoBottom from "@/components/bottom.vue";

export default {
  name: "videoHome",
  components: {
    videoNav,
    videoBottom
  },
  data() {
    return {
      videoAlive: true
    };
  },
  provide() {
    return {
      routerRefresh: this.routerRefresh,
      reload: this.reload
    };
  },
  methods: {
    reload() {
      this.videoAlive = false;
      this.$nextTick(() => {
        this.videoAlive = true;
      });
    },
    routerRefresh(routerAction) {
      console.log("routerRefresh called");
      this.videoAlive = false;
      this.$nextTick(() => {
        if (routerAction) {
          this.$router.push(routerAction);
        }
        this.$nextTick(() => {
          this.videoAlive = true;
        });
      });
    }
  }
};
</script>
